import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
} from "@material-ui/core";
import axios from "../../../../../axios-instance/backendAPI";
import { Formik } from "formik";
import * as yup from "yup";
import TableCell from "@material-ui/core/TableCell";
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../../../../../store/actions/index';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { formatCoins } from "../../../../../helpers/common";

const Deposit = (props) => {

  const [userInfo, setUserInfo] = useState({});
  const balance = useSelector((state) => state.update.balance);
  const exposure = useSelector((state) => state.update.exposure);
  const username = useSelector((state)=> state.auth.username);
  const token = useSelector(state => state.auth.token)
  const isAdmin = props.uplink.toLowerCase() === "admin"
  const dispatch = useDispatch()

  const schema = yup.object({
    chips: yup
      .number()
      .required(<p style={{ color: "red" }}>Free chips required</p>)
      .min(
        1,
        <p style={{ color: "red" }}>Free chips must be greater than 1</p>
      ),
    password: yup
      .string()
      .required(<p style={{ color: "red" }}>Password required</p>),
  });

  useEffect(() => {
    axios
      .get("/user/userBalanceInfo/" + props.uplink, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUserInfo(response.data.data[0]);
        if (response.data.message === "Invalid Token...") {
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.uplink]);

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(data, { setErrors, resetForm, setSubmitting }) => {
        setSubmitting(true);

        if (userInfo.balance !== undefined && userInfo.balance < data.chips) {
          setErrors({
            chips: (
              <span style={{ color: "red" }}>
                Free chips must be smaller than upline balance
              </span>
            ),
          });
          setSubmitting(false);
          return;
        }

        const payload = {
          uplink: props.uplink,
          downlink: props.downlink,
          downlink_type: props.downlink_type,
          money: data.chips,
          password: data.password
        };

        axios
          .patch("/user/deposit", payload, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.data.success) {
              alertify.success(response.data.message);
              props.updateRows();
              if(props.uplink === username){
                dispatch(actions.updateBalanceOrExposure(
                  isAdmin ? balance + data.chips : balance - data.chips,
                  isAdmin ? exposure : exposure + data.chips
                ));
              }
              resetForm({
                chips: "",
                passsword: "",
              });
              props.handleClose();
            } else {
              setErrors({
                password: (
                  <span style={{ color: "red" }}>{response.data.message}</span>
                ),
              });
            }
            setSubmitting(false);
          })
          .catch((error) => {
            console.log(error);
            setSubmitting(false);
          });
      }}
      initialValues={{
        chips: "",
        password: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        touched,
        errors,
      }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <h3>Deposit</h3>
          <hr />
          <Grid
            className={props.gridy}
            container
            spacing={1}
            justifyContent="space-between"
          >
            <Grid item xs={12}>
              <TextField
                label="Free chips"
                variant="outlined"
                size="small"
                onChange={handleChange}
                value={values.chips}
                name="chips"
                type="number"
                autoFocus
              />
            {touched.chips && errors.chips}
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Password"
                variant="outlined"
                size="small"
                onChange={handleChange}
                value={values.password}
                name="password"
                type="password"
              />
            {touched.password && errors.password}
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {isAdmin
                      ? "Coins_generated"
                      : props.uplink + " Free Chips"}
                  </TableCell>
                  <TableCell>
                    {isAdmin
                      ? props.coins_generated
                      : (formatCoins(userInfo.balance))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{props.downlink} Balance</TableCell>
                  <TableCell>{formatCoins(props.userBalance)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {isAdmin
                      ? " New Coins_generated"
                      : props.uplink + " New Free Chips"}
                  </TableCell>
                  <TableCell>
                    {isAdmin
                      ? formatCoins(props.coins_generated + values.chips)
                      : formatCoins(userInfo.balance - values.chips)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{props.downlink} New Balance</TableCell>
                  <TableCell>{formatCoins(props.userBalance + values.chips)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            autoFocus
            style={{marginTop: '5px'}}
          >
            Deposit
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default Deposit;
