import React from "react";
import { Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import axios from "../../../../../axios-instance/backendAPI";
import { Formik } from "formik";
import * as yup from "yup";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { useSelector } from "react-redux";
import { users } from "../../../../../helpers/constant";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: '100%',
    maxWidth: 250,
    [theme.breakpoints.down("sm")]: {
      maxWidth: '100%',
    },
  },
}));

const schema = yup.object({
  username: yup
    .string()
    .required(<p style={{ color: "red" }}>Username required</p>)
    .max(
      10,
      <p style={{ color: "red" }}>
        Username can't be greater than 10 characters
      </p>
    ),
  password: yup
    .string()
    .min(
      8,
      <p style={{ color: "red" }}>Password must be atleast 8 characters long</p>
    )
    .max(
      30,
      <p style={{ color: "red" }}>
        Password must be less than 30 characters long
      </p>
    )
    .required(<p style={{ color: "red" }}>Password required</p>),
  fullname: yup
    .string()
    .required(<p style={{ color: "red" }}>Full name required</p>)
    .max(
      25,
      <p style={{ color: "red" }}>
        Full name can't be greater than 25 characters
      </p>
    ),
  partnership: yup
    .number()
    .integer(<p style={{ color: "red" }}>Partnership must be an integer</p>)
    .required(<p style={{ color: "red" }}>Partnership required</p>)
    .min(0,<p style={{ color: "red" }}>
      Can't be less than 0!
    </p>)
    .max(100,<p style={{ color: "red" }}>
      Can't be greater than 100!
    </p>),
  bookmakerCommission: yup
    .number()
    .required(<p style={{ color: "red" }}>Bookmaker commission required</p>)
    .oneOf([0, 1], <p style={{ color: "red" }}>Invalid commission value</p>),
  fancyCommission: yup
    .number()
    .required(<p style={{ color: "red" }}>Fancy commission required</p>)
    .oneOf([0, 1], <p style={{ color: "red" }}>Invalid commission value</p>),
  usertype: yup
    .number()
    .required(<p style={{ color: "red" }}>Usertype required</p>)
});

const NewUser = (props) => {
  const token = useSelector(state => state.auth.token)
  const classes = useStyles()

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(data, { setErrors, resetForm, setSubmitting }) => {
        setSubmitting(true);
        const payload = {
          downlink: data.username.toLowerCase(),
          uplink: props.uplink,
          password: data.password,
          fullname: data.fullname,
          usertype: data.usertype,
          partnership: data.partnership,
          bookmakerCommission: data.bookmakerCommission,
          fancyCommission: data.fancyCommission
        };
        axios
          .post("/user", payload, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.data.success) {
              alertify.success(response.data.message);
              props.updateRows();
              resetForm({
                username: "",
                passsword: "",
                fullname: "",
                partnership: "",
                bookmakerCommission: "",
                fancyCommission: "",
                usertype: props.usertype
              });
              props.handleClose();
            } else {
              setErrors({
                username: (
                  <span style={{ color: "red", display: 'block' }}>
                    {response.data.message}
                  </span>
                ),
              });
            }
            setSubmitting(false);
          })
          .catch((error) => {
            console.log(error);
            setSubmitting(false);
          });
      }}
      initialValues={{
        username: "",
        password: "",
        fullname: "",
        partnership: 100,
        bookmakerCommission: 1,
        fancyCommission: 1,
        usertype: props.usertype
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        touched,
        errors,
      }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <h3>Add {users
            .filter((obj) => {
              return obj.usertype === values?.usertype?.toString();
            })
            .map((obj) => obj.type)}{" "} for {props.uplink}</h3>
          <hr />
          <Grid
            className={props.gridy}
            container
            spacing={2}
          >
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Username"
                variant="outlined"
                size="small"
                onChange={handleChange}
                value={values.username}
                name="username"
                className={classes.inputField}
              />
              {touched.username && errors.username}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Password"
                variant="outlined"
                size="small"
                onChange={handleChange}
                value={values.password}
                name="password"
                type="password"
                className={classes.inputField}
              />
              {touched.password && errors.password}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Fullname"
                variant="outlined"
                size="small"
                name="fullname"
                onChange={handleChange}
                value={values.fullname}
                className={classes.inputField}
              />
              {touched.fullname && errors.fullname}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Partnership"
                variant="outlined"
                size="small"
                name="partnership"
                onChange={handleChange}
                value={values.partnership}
                className={classes.inputField}
              />
              {touched.partnership && errors.partnership}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" size="small" className={classes.inputField}>
                <InputLabel>Bookmaker Commission</InputLabel>
                <Select
                  name="bookmakerCommission"
                  value={values.bookmakerCommission}
                  onChange={handleChange}
                  label="Bookmaker Commission"
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>{props.commission.bookmaker_com}%</MenuItem>
                </Select>
                {touched.bookmakerCommission && errors.bookmakerCommission}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" size="small" className={classes.inputField}>
                <InputLabel>Fancy Commission</InputLabel>
                <Select
                  name="fancyCommission"
                  value={values.fancyCommission}
                  onChange={handleChange}
                  label="Fancy Commission"
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>{props.commission.fancy_com}%</MenuItem>
                </Select>
                {touched.fancyCommission && errors.fancyCommission}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" size="small" className={classes.inputField}>
                <InputLabel>Usertype</InputLabel>
                <Select
                  name="usertype"
                  value={values.usertype}
                  onChange={handleChange}
                  label="Usertype"
                >
                  {users
                    .filter((user) => user.usertype >= props.usertype)
                    .map((user) => (
                      <MenuItem key={user.usertype} value={user.usertype}>
                        {user.type}
                      </MenuItem>
                    ))}
                </Select>
                {touched.usertype && errors.usertype}
              </FormControl>
            </Grid>
          </Grid>
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            style={{marginRight: '5px'}}
            autoFocus
          >
            Add
          </Button>
          <Button
            onClick={props.handleClose}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default NewUser;
