import React, { useEffect, useState, useRef, forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import CurrentBets from './CurrentBets/CurrentBets'
import axios from '../../../../../axios-instance/backendAPI';
import MarketAnalysis from './MarketAnalysis/MarketAnalysis';
import { useSelector } from "react-redux";
import Modal from '../../../../UI/Modal/Modal'
import StakeValues from './StakeValues/StakeValues';
import { dateFormatter, formatCoins, transformToBookmakerOdds } from '../../../../../helpers/common';

function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Typography>{children}</Typography>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
}

const BetSlip = forwardRef((props,ref) =>{

    const usertype = useSelector(state => state.auth.usertype)

    const useStyles = makeStyles((theme) => ({
        head: {
            background: "var(--header_color)",
            height: "30px",
            color: "var(--heading)",
            display: 'flex',
            fontWeight: 700,
            fontSize: 'var(--large_font)',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '1px 7px',
            [theme.breakpoints.down('sm')]: {
                width: '97%',
                fontSize: 'var(--small_font)',
            }
        },
        root: {
            width: '98%',
            margin: '0 auto',
            paddingLeft: '10px',
            [theme.breakpoints.down('sm')]: {
                display: props.display,
                width: '100%',
                marginTop: '15px',
                padding: '0px 4px',
            }
        },
        edit: {
            cursor: 'pointer'
        },
        tabs: {
            background: '#f8f9fa',
            color: 'var(--header_color)',
            display: 'flex',
        },
        active: {
            fontSize: 'var(--medium_font)',
            textTransform: 'capitalize',
            fontWeight: 700,
            textAlign: 'center',
            width: usertype==='5'? '50%' : '33.33%',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
            }
        },
        inactive: {
            fontSize: 'var(--medium_font)',
            textTransform: 'capitalize',
            textAlign: 'center',
            width: usertype==='5'? '50%' : '33.33%',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
            }
        }
    }));

    const username = useSelector(state => state.auth.username)
    const currentBets = useSelector(state => state.update.currentBets)
    const token = useSelector(state => state.auth.token)
    const [bets, setBets] = useState([])
    const [userHistory, setUserHistory] = useState([])
    const [userInfo, setUserInfo] = useState({ username: '', usertype: '' })
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false)
    const flagRef = useRef(false)
    const classes = useStyles()
    const location = useLocation()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const ahead = (username) => {

        if (userInfo.usertype !== '4') {
            let users = [...userHistory]
            users.push(username)
            setUserHistory(users)
            getUserInfo(username)
        }

    }

    const back = () => {

        if (userHistory.length !== 1) {
            const users = [...userHistory]
            users.pop()
            getUserInfo(users[users.length - 1])
            setUserHistory([...users])
        } else {
            myInfo()
        }
    }

    const onClose = () => {
        setOpen(false)
    }

    const getUserInfo = (username) => {

        axios
            .get(`/user/userBalanceInfo/${username}`, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then((response) => {

                if (response.data.success) {
                    const user = {
                        username: response.data.data[0].username,
                        usertype: response.data.data[0].usertype
                    }
                    setUserInfo(user)
                }

            })
            .catch((err) => {
                console.log(err);
            });
    }

    const myInfo = () => {

        const user = {
            username: username,
            usertype: usertype
        }
        setUserInfo(user)

        const cur_user = []
        cur_user.push(username)
        setUserHistory(cur_user)
    }

    const getCurrentBets = () => {

        if (location.pathname.includes('fullmarket') && props.eventId) {
            axios.get(`/user/getCurrentBetsByEvent/${props.eventId}`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {

                    if (response.data.success) {
                        response.data.data = response.data.data.map(row => {

                            if(row.type === 'bookmaker')
                                row.odds = transformToBookmakerOdds(row.odds)

                            return {
                                username: row.username,
                                odds: `${row.code===1 ? `${row.odds}/${row.selection}` : row.odds}`,
                                stake: formatCoins(row.stake),
                                runner: `${row.runner}${row.type === 'fancy'? `/${row.user_rate}`:`/${row.market}`}`,
                                bet_id: row.bet_id,
                                selection: row.selection,
                                seniorsuper: row.seniorsuper,
                                supermaster: row.supermaster,
                                master: row.master,
                                type: row.type,
                                code: row.code,
                                placed_at: dateFormatter(row.placed_at)
                            }
                        })
                        setBets(response.data.data)
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }

    useEffect(() => {
        myInfo()
    }, [username, props.marketId])

    useEffect(() => {

        if (flagRef.current) {
            handleChange(null, 2)
        }
        flagRef.current = true
    }, [props.flag])

    useEffect(() => {
        getCurrentBets()

        if (usertype !== "5") {
            const timer = usertype === "1" ? 10000 : 30000
            const intervalId = setInterval(getCurrentBets, timer)
            return () => clearInterval(intervalId) // cleanup on unmount
        }
    }, [props.eventId])

    useEffect(() => {
        if(!currentBets) return;
        const formattedCurrentBets = {...currentBets}
        formattedCurrentBets.placed_at = dateFormatter(currentBets.placed_at)
        formattedCurrentBets.runner=`${currentBets.runner}${currentBets.type === 'fancy'? `/${currentBets.user_rate}`:`/${currentBets.market}`}`
        formattedCurrentBets.stake = formatCoins(currentBets.stake)
        if(currentBets.type==='bookmaker')
            formattedCurrentBets.odds = transformToBookmakerOdds(currentBets.odds)
        const newBets = [
            formattedCurrentBets,
            ...bets
        ]
        setBets(newBets)
    },[currentBets])

    let toRender = null
    if (location.pathname.includes('fullmarket')) {
        const fancyBets = bets.filter(bet=>bet.type==="fancy")
        toRender = <div>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    className={classes.tabs}
                    indicatorColor='primary'
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab
                        label={`All Bets(${bets.length})`}
                        {...a11yProps(0)}
                        className={value === 0 ? classes.active : classes.inactive} />
                    <Tab
                        label={`Fancy Bets(${fancyBets.length})`}
                        {...a11yProps(0)}
                        className={value === 1 ? classes.active : classes.inactive} />
                    {usertype !== '5' ? <Tab
                        label="Current Position" {...a11yProps(1)}
                        className={value === 2 ? classes.active : classes.inactive} /> : null}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className={classes.myTab}>
                <CurrentBets
                    eventId={props.eventId}
                    myUsertype={usertype}
                    bets={bets} 
                />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.myTab}>
                <CurrentBets
                    eventId={props.eventId}
                    myUsertype={usertype}
                    bets={fancyBets}
                />
            </TabPanel>
            {usertype !== '5' ? <TabPanel value={value} index={2} className={classes.myTab}>
                <MarketAnalysis
                    eventId={props.eventId}
                    marketId={props.marketId}
                    marketName={props.marketName}
                    username={userInfo.username}
                    usertype={userInfo.usertype}
                    ahead={ahead}
                    back={back} />
            </TabPanel> : null}
        </div>
    }

    return (
        <div className={classes.root} ref={ref}>
            <Modal open={open} onClose={onClose}>
                <StakeValues
                    onClose={onClose}
                    changeStakeChanged={props.changeStakeChanged}
                />
            </Modal>
            <div className={classes.head}>
                <div>
                    Bet Slip
                </div>
                {usertype !== '5' ?
                <div></div> :
                <div
                    className={classes.edit}
                    onClick={() => {
                        if (usertype === '5') {
                            setOpen(true)
                        }
                    }}>
                    <i className="far fa-edit"></i> Edit Stakes
                </div>}
            </div>
            {toRender}
        </div>
    )
})

export default BetSlip